import { Suspense } from 'solid-js';
import type { RouteSectionProps } from '@solidjs/router';

export default function PressReleaseLayout(props: RouteSectionProps) {
	return (
		<>
			<Suspense>{props.children}</Suspense>
		</>
	);
}
